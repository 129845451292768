import { AdAttemptStatus } from '../api/campaign-status.enum';
import { IPublishedAdRef } from '../api/campaign.interface';
import { Model } from '../lib/model';

export class PublishedAdRef extends Model<PublishedAdRef> implements IPublishedAdRef {
    public id: string;
    public name?: string;
    public zipUrl?: string;
    public handled: boolean;
    public failed: boolean;
    public status: AdAttemptStatus;

    public deserialize(json: any): PublishedAdRef {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;
        this.zipUrl = json.zipUrl;
        this.handled = json.handled;
        this.failed = json.failed;
        this.status = json.status;

        return this;
    }
}
