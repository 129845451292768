import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { IAdGroup } from '@shared/models/campaign/api/campaign.interface';
import { ObjectPickerComponent } from '../objectPicker.component';
import { AnalyticsApiService } from '@analytics/services/analyticsApi.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'adGroupPicker',
    templateUrl: 'adgroup.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [NgxDatatableModule, NgIf, UIModule]
})
export class AdGroupPickerComponent extends ObjectPickerComponent {
    @Input() public campaignID: string;
    constructor(
        private analyticsApiService: AnalyticsApiService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.StudioCampaign;

        this.tableRows = new Array<ExObjectItem<IAdGroup>>();
    }

    protected async loadItems(): Promise<void> {
        this.loading = true;

        const compaign = await this.analyticsApiService.getCampaign(this.campaignID);

        this.tableRows = compaign.adGroups;

        super.loadItems();

        this.datatable.recalculate();

        this.loading = false;
    }
}
