export interface ListServiceError {
    errorType: ListServiceErrorTypes;
    message: string;
    entityId: string;
    entityType: string;
}

export interface ListServiceResourcesError {
    errorType: ListServiceErrorTypes;
    message: string;
    ids: string;
}

export enum ListServiceErrorTypes {
    UnknownError = 'UnknownError',
    NameExistsError = 'NameExistsError',
    ResourceNotFoundError = 'ResourceNotFoundError',
    ReadError = 'ReadError',
    WriteError = 'ReadError',
    EntityDeletedError = 'EntityDeletedError',
    ResourceNotDeletedError = 'ResourceNotDeletedError',
    ResourceActionUnsupportedError = 'ResourceActionUnsupportedError'
}
