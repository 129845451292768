export class Country {
    public id = '';
    public countryCode = '';
    public latitude: number;
    public longitude: number;
    public name = '';
    public threeLetterISORegionName = '';

    constructor(obj: Partial<Country> = {}) {
        Object.assign(this, obj);
    }

    public static parse(countryObject: any): Country {
        const country: Country = new Country();

        country.countryCode = countryObject.country || countryObject.countryCode;
        country.longitude = parseFloat(countryObject.longitude);
        country.latitude = parseFloat(countryObject.latitude);
        country.name = countryObject.name;

        return country;
    }
}
