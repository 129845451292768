import { EventEmitter, Injectable } from '@angular/core';
import { HistoryView } from '@shared/models/routing-parameter.model';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    public previousView: HistoryView;
    public previousViewChanged: EventEmitter<HistoryView> = new EventEmitter<HistoryView>();

    constructor(private readonly sessionService: SessionService) {}

    private getHistory(): History {
        return new History().deserialize(window.localStorage.getItem('history'));
    }

    private saveHistory(history: History): void {
        window.localStorage.setItem('history', history.serialize());
    }

    public setPreviousView(view: HistoryView): void {
        this.previousView = { ...view };
        this.previousViewChanged.emit(this.previousView);
    }

    public getLastVisitedLocation(): any {
        const history: any = this.getHistory();

        return history.lastVisitedLocation;
    }

    public saveLastVisitedLocation(path: string, view?: string): void {
        const history: History = this.getHistory();
        const brandId: string = this.sessionService.user.brand.id;

        history.setLastVisitedLocation(brandId, this.sessionService.user.brand.name, path);

        // TODO: Maybe make a Cookie service
        const date: any = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 Days
        document.cookie = `lastBrand=${brandId}; expires=${date.toGMTString()};path=/`;
        if (view) {
            document.cookie = `lastView=${view}; expires=${date.toGMTString()};path=/`;
        }

        this.saveHistory(history);
    }

    public addVisitedObject(id: string, name: string, image: string, type: string): void {
        const history: any = this.getHistory();

        history.addObjectActivity(this.sessionService.user.brand.id, {
            id,
            name,
            image,
            type
        });

        this.saveHistory(history);

        this.saveLastVisitedLocation(name);
    }

    public getVisitedObjects(): any {
        const history: History = this.getHistory();

        return history.getLatestObjectsForBrand(this.sessionService.user.brand.id);
    }
}

class History {
    public lastVisitedLocation: any = null;
    public activity: any = [];

    public setLastVisitedLocation(brandId: string, brandName: string, path: string): void {
        this.lastVisitedLocation = {
            brandName,
            path,
            brandId
        };

        const brand: any = this.activity.find((activity: any) => activity.brandId === brandId);

        if (brand) {
            brand.count += 1;
        } else {
            this.activity.push({
                brandId,
                count: 1,
                objects: []
            });
        }

        // Clean up if too many brands in activity list
        this.cleanUpActivity(brandId);
    }

    public addObjectActivity(brandId: string, object: any): void {
        const brand: any = this.activity.find((activity: any) => {
            return activity.brandId === brandId;
        });

        if (brand) {
            const existingObject: any = brand.objects.find((brandObject: any) => {
                return brandObject.id === object.id;
            });

            // If object already exists in the list remove the old object
            if (existingObject) {
                brand.objects.splice(brand.objects.indexOf(existingObject), 1);
            }

            brand.objects.unshift(object);

            // Keep the object list max 5 items long
            if (brand.objects.length > 5) {
                brand.objects.pop();
            }
        } else {
            this.activity.push({
                brandId,
                count: 0,
                objects: [object]
            });
        }

        // Clean up if too many brands in activity list
        this.cleanUpActivity(brandId);
    }

    public getLatestObjectsForBrand(brandId: string): any {
        if (!this.activity) {
            return [];
        }

        const brand: any = this.activity.find((activity: any) => activity.brandId === brandId);

        return brand ? brand.objects : [];
    }

    private cleanUpActivity(brandId: string): void {
        if (this.activity.length > 20) {
            const relevantActivites: any[] = this.activity.filter((a: any) => {
                return a.brandId !== brandId;
            });

            const oldestVisitedBrand: any = relevantActivites.sort((a: any, b: any) => {
                return a.count > b.count ? 1 : b.count > a.count ? -1 : 0;
            })[0];

            this.activity.splice(this.activity.indexOf(oldestVisitedBrand), 1);
        }
    }

    public deserialize(json: any): any {
        const history: any = JSON.parse(json) || {};

        this.lastVisitedLocation = history.b
            ? {
                  brandName: history.b.n,
                  path: history.b.p,
                  brandId: history.b.bi
              }
            : null;

        this.activity = history.a
            ? history.a.map((activity: any) => {
                  return {
                      brandId: activity.bi,
                      count: activity.c || 0,
                      objects: activity.o
                          ? activity.o.map((object: any) => {
                                return {
                                    id: object.i,
                                    name: object.n,
                                    image: object.im,
                                    type: object.t
                                };
                            })
                          : []
                  };
              })
            : [];

        return this;
    }

    public serialize(): any {
        return JSON.stringify({
            b: this.lastVisitedLocation
                ? {
                      n: this.lastVisitedLocation.brandName,
                      p: this.lastVisitedLocation.path,
                      bi: this.lastVisitedLocation.brandId
                  }
                : {},
            a: this.activity.map((activity: any) => {
                return {
                    bi: activity.brandId,
                    c: activity.count,
                    o: activity.objects.map((object: any) => {
                        return {
                            i: object.id,
                            n: object.name,
                            im: object.image,
                            t: object.type
                        };
                    })
                };
            })
        });
    }
}
