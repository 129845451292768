// libs
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { Commentable } from '@shared/interfaces/commentable.interface';
import { Comment } from '@shared/models/comment.model';
import { CommentsService } from './comments.service';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { CommentInputComponent } from './commentInput.component';
import { ProfileImageComponent } from '../profileImage/profileImage.component';
import { UIModule } from '@bannerflow/ui';
import { ToTimeAgoPipe } from '../../pipes/toTimeAgo.pipe';

@Component({
    selector: 'comments',
    styleUrls: ['./comments.component.scss'],
    templateUrl: 'comments.component.html',
    providers: [CommentsService],
    standalone: true,
    imports: [NgIf, CommentInputComponent, NgFor, ProfileImageComponent, UIModule, NgStyle, ToTimeAgoPipe]
})
export class CommentsComponent implements OnInit, OnDestroy {
    @Input() public itemToComment: Commentable;
    @Input() public theme: string;
    @Input() public hideInput = false;
    @Input() public inputPosition = 'top';
    @Input() public autoFocus = false;
    @Input() private parent: Commentable;
    @Input() private type: string;

    @Output() private onCommentAdded: EventEmitter<Comment> = new EventEmitter<Comment>();
    @Output() private onCommentRemoved: EventEmitter<Comment> = new EventEmitter<Comment>();

    public message: string;
    public userEmail: string;
    public commentToReply: Comment;
    public SessionService = SessionService;
    public isReply = false;

    constructor(private readonly commentsService: CommentsService, public readonly sessionService: SessionService) {}

    public postComment(message: any): void {
        const replyComment: any = message[1];
        const newComment: Comment = new Comment();
        newComment.text = message[0];
        newComment.bannerSetId = this.parent ? this.parent.id : this.itemToComment.id;
        newComment.adId = this.parent ? this.itemToComment.id : null;
        newComment.commentId = replyComment ? replyComment.id : null;
        newComment.created = new Date();
        newComment.user = this.sessionService.user;

        this.commentsService.postComment(newComment).then((createdComment: Comment) => {
            createdComment.new = true;

            if (replyComment) {
                if (!replyComment.comments) {
                    replyComment.comments = [];
                }

                replyComment.comments.push(createdComment);
            } else {
                this.itemToComment.comments.unshift(createdComment);
            }

            this.onCommentAdded.emit(createdComment);
        });
    }

    public reply(comment: Comment): void {
        this.commentToReply = comment;
        this.isReply = true;
    }

    public delete(comment: Comment, parent: Comment = null): void {
        this.commentsService.deleteComment(comment).then(
            () => {
                const currentComment: Comment | Commentable = parent ? parent : this.itemToComment;
                currentComment.comments.splice(currentComment.comments.indexOf(comment), 1);

                this.onCommentRemoved.emit();
            },
            error => {}
        );
    }

    public ngOnInit(): void {
        this.theme = this.theme || 'default';
    }

    public ngOnDestroy(): void {
        this.message = '';
    }
}
