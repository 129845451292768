<ui-dialog
    #dialog
    class="approveDialog"
    (onClose)="cancel()">
    <h4>Hello! Nice to meet you, who are you?</h4>
    <p>In order to approve and write comments you need to specify your email address so we will know who you are.</p>
    <input
        #emailInput
        (keyup.enter)="continue()"
        [(ngModel)]="email"
        type="text"
        placeholder="Enter your email..." />
    <div class="action-buttons">
        <ui-button
            (click)="cancel()"
            text="Close"></ui-button>
        <ui-button
            (click)="continue()"
            type="primary"
            text="Let me continue"></ui-button>
    </div>
</ui-dialog>
