import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { PermissionService } from '@core/services/internal/permission.service';
import { SessionService } from '@core/services/internal/session.service';

export const userResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const sessionService = inject(SessionService);
    const permissionService = inject(PermissionService);

    const { accountSlug, brandSlug } = route.params;

    const user = await sessionService.getUser(accountSlug, brandSlug).catch(async error => {
        console.error(error);
        await router.navigateByUrl('/404');
    });

    if (user) {
        permissionService.setUserRole(user.role.role);
    }
};
