import { View } from '@shared/enums/view.enum';
import { Serializable } from '@shared/interfaces/serializable.interface';
import { Account } from './account.model';
import { Brand } from './brand.model';
import { Role } from './role.model';

export class UserInfo
{
    public id: string;
    public externalId: string;
    public firstName: string;
    public lastName: string;
}

export class User implements Serializable<User> {
    public id: string;
    public externalId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public timezone: string;
    public profile: string;
    public anonymous: boolean;
    public category: string;
    public created: Date;
    public lastLogin: Date;
    public permissions: any[];
    public defaultView: View;
    public defaultBrand: string;
    public defaultLanguages: any;
    public twoFactorEnabled: boolean;
    public role: Role;
    public confirmed: boolean;
    public title?: string;

    public isEmployee: boolean;

    public account: Account;
    public brand: Brand;
    public brands: Brand[];

    public userLoginType: string;

    public getInitials(): string {
        return this.firstName[0] + this.lastName[0];
    }

    public deserialize(json: any): User {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.title = json.title;
        this.externalId = json.externalId;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.email = json.email;
        this.timezone = json.timezone;
        this.category = json.category;
        this.confirmed = json.confirmed;

        // Roles can come as objects or as a role id. The rolie ID is an integer.
        const generateRoleFromId = !isNaN(parseInt(json.role, 10));
        this.role = new Role().deserialize(generateRoleFromId ? { role: json.role } : json.role);

        this.defaultView = json.defaultView as View;
        this.defaultBrand = json.defaultBrand;
        this.defaultLanguages = json.defaultLanguages;

        this.twoFactorEnabled = json.twoFactorEnabled;

        // todo: rename
        this.profile = json.profile;

        this.isEmployee = json.isEmployee;

        this.anonymous = json.anonymous;
        this.permissions = json.permissions;
        this.account = Account.deserialize(json.account);
        this.brand = new Brand().deserialize(json.brand);
        this.brands = json.brands;

        this.created = new Date(json.created);
        this.lastLogin = new Date(json.lastLogin);
        this.userLoginType = json.userLoginType;

        return this;
    }
}
