<div class="comments comments--{{ theme }}">
    <comment-input
        *ngIf="inputPosition === 'top' && !hideInput"
        [autoFocus]="autoFocus"
        (postComment)="postComment($event)"></comment-input>
    <div class="comments__commentList">
        <div
            class="comments__comment"
            *ngFor="let comment of itemToComment.comments">
            <div class="comments__commentInner">
                <div class="comments__userImage">
                    <profileImage [user]="comment.user"></profileImage>
                </div>
                <div
                    class="comments__userName"
                    uiTooltip="{{ comment.user.firstName }} {{ comment.user.lastName }}">
                    {{ comment.user.firstName }} {{ comment.user.lastName }}
                </div>
                <div class="comments__commentText">{{ comment.text }}</div>
                <div class="comments__sub">
                    {{ comment.created | toTimeAgo }}
                    <span
                        [ngStyle]="{
                            display: sessionService.isLoggedIn() && comment.comments.length === 0 ? 'inline' : 'none'
                        }"
                        >•&nbsp;<a
                            class="comments__delete"
                            (click)="delete(comment)"
                            >Delete</a
                        ></span
                    >
                    • <a (click)="reply(comment)">Reply</a>
                </div>
            </div>
            <div class="comments__commentList">
                <div
                    class="comments__comment"
                    *ngFor="let subComment of comment.comments">
                    <div class="comments__commentInner">
                        <div class="comments__userImage">
                            <profileImage [user]="subComment.user"></profileImage>
                        </div>
                        <div
                            class="comments__userName"
                            uiTooltip="{{ subComment.user.firstName }} {{ subComment.user.lastName }}">
                            {{ subComment.user.firstName }} {{ subComment.user.lastName }}
                        </div>
                        <div class="comments__commentText">{{ subComment.text }}</div>
                        <div class="comments__sub">
                            {{ subComment.created | toTimeAgo }}
                            <span [ngStyle]="{ display: sessionService.isLoggedIn() ? 'inline' : 'none' }">
                                •
                                <a
                                    class="comments__delete"
                                    (click)="delete(subComment, comment)"
                                    >Delete</a
                                ></span
                            >
                            • <a (click)="reply(comment)">Reply</a>
                        </div>
                    </div>
                </div>
                <comment-input
                    *ngIf="comment === commentToReply"
                    [comment]="comment"
                    [autoFocus]="autoFocus"
                    [isReply]="isReply"
                    (postComment)="postComment($event)"></comment-input>
            </div>
        </div>
    </div>
    <comment-input
        *ngIf="inputPosition === 'bottom' && !hideInput"
        [autoFocus]="autoFocus"
        (postComment)="postComment($event)"></comment-input>
</div>
