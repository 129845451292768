import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlToDomain',
    standalone: true
})
export class UrlToDomainPipe implements PipeTransform {
    transform(url: string): string {
        if (!url) {
            return '';
        }
        let d: string = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n]+)/im)[1];
        d = d.match(/\?/) ? d.split('?')[0] : d;
        return d;
    }
}
