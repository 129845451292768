import { IUser } from '../api/campaign.interface';
import { Model } from '../lib/model';

export class PublishUser extends Model<PublishUser> implements IUser {
    public id: string;
    public brandId: string;
    public accountId: string;

    public deserialize(json: any): PublishUser {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.brandId = json.brandId;
        this.accountId = json.accountId;

        return this;
    }
}
