<div
    class="welcomePanel"
    [ngClass]="{ 'welcomePanel--visible': visible, 'with-sidebar': isSidebarExpanded | async }">
    <div class="welcomePanel__content">
        <div class="welcomePanel__skip">
            <ui-checkbox
                id="interaction-checkbox-hide-welcome-panel"
                [(selected)]="showOnLogin"
                (selectedChange)="showOnLoginChange()"></ui-checkbox>
            Hide this on login
        </div>
        <div class="welcomePanel__text">
            <div class="welcomePanel__textHeader">Welcome {{ sessionService.user.firstName }}!</div>
            <div
                class="welcomePanel__textBody"
                *ngIf="sessionService.user.brand.impressionsLastWeek > 0">
                <p>
                    The last seven days your brand has had a total of<br />
                    <strong>{{ sessionService.user.brand.impressionsLastWeek | number }}</strong> new impressions.
                </p>
                <p>Cheers,<br />Bannerflow <span class="welcomePanel__textButterfly"></span></p>
            </div>
            <div
                class="welcomePanel__textBody"
                *ngIf="sessionService.user.brand.impressionsLastWeek === 0">
                <p>Try publish some of your banners, it's fun. We promise.</p>
                <p>Cheers,<br />Bannerflow <span class="welcomePanel__textButterfly"></span></p>
            </div>
        </div>
        <div class="welcomePanel__latestObjects">
            <div class="welcomePanel__latestObjectsHeader">Recently viewed</div>
            <div class="welcomePanel__latestObjectsList">
                <div
                    class="welcomePanel__latestObjectsItem"
                    *ngFor="let object of visitedObjects"
                    (click)="gotoObject(object)">
                    <div
                        class="welcomePanel__latestObjectsItemIcon  welcomePanel__latestObjectsItemIcon--{{
                            object.type
                        }}"
                        *ngIf="!object.image"></div>
                    <div
                        class="welcomePanel__latestObjectsItemThumb"
                        [ngStyle]="{ backgroundImage: 'url(' + object.image + ')' }"
                        *ngIf="object.image"></div>
                    <div class="welcomePanel__latestObjectsItemTitle">
                        {{ object.name | ellipsis : 50 : true }}
                    </div>
                </div>
                <div
                    class="welcomePanel__latestObjectsItem welcomePanel__latestObjectsItem--ghost"
                    *ngFor="let placeholder of placeholders">
                    <div class="welcomePanel__latestObjectsItemThumb"></div>
                    <div class="welcomePanel__latestObjectsItemTitle">
                        <span>###</span>&nbsp;&nbsp;<span>######</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
