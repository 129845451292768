import { IAppConfig } from './app.config.type';

export const config: IAppConfig = {
    BUILD_VERSION: '<VERSION>',
    STAGE: 'production',
    APP_URL: 'https://analytics.bannerflow.com',
    IDP_URL: 'https://idp.bannerflow.com',
    B2_URL: 'https://app.bannerflow.com',
    STUDIO_URL: 'https://studio.bannerflow.com',
    CAMPAIGN_SERVICE_URL: 'https://api.bannerflow.com/cs',
    PUBLISH_SERVICE_URL: 'https://api.bannerflow.com/ps',
    MIGRATION_SERVICE_URL: 'https://migrationservice.bannerflow.com',
    ANALYTICS_BACKEND_SERVICE_URL: 'https://api.bannerflow.com/ab',
    CAMPAIGN_MANAGER_URL: 'https://cm.bannerflow.com',
    AD_SERVICE_URL: 'https://c.bannerflow.net',
    AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING:
        'Endpoint=https://bf-shared-ac.azconfig.io;Id=gdD1-l9-s0:gBi7bAZ/eHTR9I4XFT7c;Secret=B7Er0advds2mjybouWaNOYTPy4lpZNz0jNb7FZZs+Yo=',
    SOCIAL_CAMPAIGN_SERVICE_URL: 'https://scs.bannerflow.com',
    SOCIAL_CAMPAIGN_MANAGER_URL: 'https://scm.bannerflow.com',
    SOCIAL_ACCOUNT_SERVICE_URL: 'https://sas.bannerflow.com',
    SCHEDULE_MIGRATION_CLIENT_URL: 'https://smc.bannerflow.com',
    GTM_ID: 'GTM-W5CTW5Z',
    INTERCOM_ID: 'vc7jxmzv',
    LIST_SERVICE_URL: 'https://api.bannerflow.com/list-service',
    ACCOUNT_ACCESS_URL: 'https://api.bannerflow.com/account-access',
    BRAND_MANAGER_URL: 'https://api.bannerflow.com/brand-manager',
    API_GATEWAY_URL: 'https://api.bannerflow.com',
    CLIENT_ID: 'pojemgnAOu2FiMJc3c9REcp2BMOxY1lL',
    LOGIN_URL: 'https://login.bannerflow.com'
};
