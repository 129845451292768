<div class="objectPicker objectPicker--tight">
    <div class="objectPicker__table">
        <ngx-datatable
            #datatable
            [rows]="tableRows"
            [rowHeight]="40"
            [columnMode]="'force'"
            (activate)="onTableClick($event)"
            [selected]="selectedObjects"
            (selectedChange)="selectedObjects = $event"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            [selectCheck]="canSelect">
            <ngx-datatable-column
                *ngIf="multi"
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true">
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Name"
                prop="name">
                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template>
                    <div
                        class="itemImage"
                        *ngIf="row.id"
                        [ngStyle]="{ backgroundImage: 'url(' + row.logo + ')' }"></div>
                    <span
                        [uiTooltip]="value"
                        [uiTooltipHideArrow]="true">
                        {{ value }}
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
