import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toSeconds',
    standalone: true
})
export class ToSecondsPipe implements PipeTransform {
    transform(time: string): string {
        const index = time.indexOf('.');
        const seconds = index === -1 ? time : time.substring(0, index);
        const arr = seconds.split(':');

        if (arr.length === 3) {
            return `${+arr[0] * 60 * 60 + +arr[1] * 60 + +arr[2]} seconds`;
        }

        return time;
    }
}
