import { Version } from '../version/version.model';
import { Banner } from './banner.model';
import { BannerFilter } from './bannerFilter.model';
import { BannerFormat } from './bannerFormat.model';
import { SizeFormat } from './sizeFormat.model';

export const BANNER_BUTTON_COMMENT = 'comments';
export const BANNER_BUTTON_APPROVE = 'approve';
export const BANNER_BUTTON_BANNERTAG = 'bannertag';
export const DEFAULT_BANNER_BUTTONS = [BANNER_BUTTON_COMMENT, BANNER_BUTTON_APPROVE, BANNER_BUTTON_BANNERTAG];

export class BannerGroup {
    public bannerFormat: BannerFormat;
    public banners: Banner[];
    public collapsed = false;
    public renderContent = false;

    public containsSizeFormat(sizeFormats: SizeFormat[]): boolean {
        const contains: SizeFormat = sizeFormats.find(
            (format: SizeFormat) => format.id === this.bannerFormat.sizeFormat.id
        );

        return !!contains;
    }

    public containsVersion(versions: Version[]): boolean {
        const contains: Version = versions.find((version: Version) => {
            return !!this.banners.find((banner: Banner) => version.id === banner.version.id);
        });

        return !!contains;
    }
}

export class BannerGroupList {
    public bannerGroups: BannerGroup[];
    public readonly bannerButtons: string[];

    constructor(bannerGroups: BannerGroup[], bannerButtons?: string[]) {
        this.bannerGroups = bannerGroups;
        this.bannerButtons = bannerButtons || DEFAULT_BANNER_BUTTONS;
    }

    public filter(filter: BannerFilter): BannerGroup[] {
        let result: BannerGroup[] = [];

        this.bannerGroups.forEach((bannerGroup: BannerGroup) => {
            const containsVersions: boolean = bannerGroup.containsVersion(filter.versions);

            const newBannerGroup: BannerGroup = new BannerGroup();
            newBannerGroup.bannerFormat = bannerGroup.bannerFormat;
            newBannerGroup.banners = new Array<Banner>();
            newBannerGroup.collapsed = !bannerGroup.containsSizeFormat(filter.sizeFormats);

            if (containsVersions) {
                bannerGroup.banners.forEach((banner: Banner) => {
                    const hasVersions: Version = filter.versions.find(
                        (version: Version) => version.id === banner.version.id
                    );

                    if (hasVersions) {
                        newBannerGroup.banners.push(banner);
                    }
                });
            }

            if (!filter.showApproved) {
                newBannerGroup.banners = newBannerGroup.banners.filter((banner: Banner) => {
                    return !banner.approved || !banner.approved.approved;
                });
            } else if (!filter.showNotApproved) {
                newBannerGroup.banners = newBannerGroup.banners.filter((banner: Banner) => {
                    return banner.approved && banner.approved.approved;
                });
            }

            result.push(newBannerGroup);
        });

        // Remove all groups not containing matching banners
        result = result.filter((bannerGroup: BannerGroup) => bannerGroup.banners.length > 0);

        return result;
    }

    public allExpanded(): BannerGroup[] {
        this.bannerGroups.forEach((bannerGroup: BannerGroup) => {
            bannerGroup.collapsed = false;
        });

        return this.bannerGroups;
    }

    public findBannerById(bannerId: string): Banner {
        if (bannerId) {
            for (const bannerGroup of this.bannerGroups) {
                const foundBanner: Banner = bannerGroup.banners.find((banner: Banner) => banner.id === bannerId);

                if (!!foundBanner) {
                    return foundBanner;
                }
            }
        }

        return;
    }

    public findBannerGroupByBanner(banner: Banner): BannerGroup {
        return this.bannerGroups.find((bannerGroup: BannerGroup) => {
            return !!bannerGroup.banners.find((b: Banner) => b === banner);
        });
    }

    public getBannerCount(): number {
        let count = 0;

        this.bannerGroups.forEach((bannerGroup: BannerGroup) => {
            count += bannerGroup.banners.length;
        });

        return count;
    }

    public getSizeFormats(): SizeFormat[] {
        const formats: SizeFormat[] = [];

        this.bannerGroups.forEach((bannerGroup: BannerGroup) => {
            const alreadyAdded: SizeFormat = formats.find(
                (format: SizeFormat) => format.id === bannerGroup.bannerFormat.sizeFormat.id
            );

            if (!alreadyAdded) {
                formats.push(bannerGroup.bannerFormat.sizeFormat);
            }
        });

        return formats;
    }

    public getVersions(): Version[] {
        const versions: Version[] = [];

        this.bannerGroups.forEach((bannerGroup: BannerGroup) => {
            bannerGroup.banners.forEach((banner: Banner) => {
                const hasVersions: Version = versions.find((version: Version) => version.id === banner.version.id);

                if (!hasVersions) {
                    versions.push(banner.version);
                }
            });
        });

        return versions;
    }
}
