export enum CampaignStatus {
    Unknown = 0,
    Published = 1,
    NotPublished = 2,
    Publishing = 3,
    PublishedError = 4
}
export enum AdAttemptStatus {
    Unknown = 0,
    Pending = 1,
    Success = 2,
    CreativeFailed = 3,
    CreativeTimedOut = 4,
    PublishingFailed = 5,
    PublishingTimedOut = 6
}

export enum AdStatus {
    Unknown = 0,
    Created = 1,
    Draft = 2,
    Optimizing = 4, // waiting creatives
    BeingPublished = 8, // sent to publish service
    Published = 16, // if its tag publish this means creatives generated, if not tag publish it has more steps
    PublishFailed = 32,
    CreativesFailed = 64
}

export enum PublishAttemptStatus {
    New = 0,
    Pending = 1,
    Sent = 2, // sent to publish service
    Success = 3, // all ads needs to be successful
    Failure = 4, // some ads failed not all
    AwaitingCreatives = 5 // some ads are waiting creatives to be generated on studio
}
