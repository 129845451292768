import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { BannerSetService } from '@core/services/api/bannerflow/bannerset.service';
import { SessionService } from '@core/services/internal/session.service';
import { ExObjectItem, ExObjectQuery, ExObjectResult } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { shareReplay } from 'rxjs';
import { CreativeSetListItem, CreativeSetTableData, ListItemType } from '@analytics/models/creative-set-models';
import { ListService } from '@analytics/services/list.service';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'bannerSetPicker',
    templateUrl: 'bannerset.picker.component.html',
    styleUrls: ['../objectPicker.component.scss', 'bannerset.picker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, UIModule, NgxDatatableModule, NgClass, DecimalPipe]
})
export class BannerSetPickerComponent extends ObjectPickerComponent {
    @Input() public showAmountOfBanners: boolean;
    @Input() public onlyStudio = false;
    @Input() showOnlyBannerSets = false;

    private hasStudioAccess: boolean = this.sessionService.hasFeature(SessionService.FEATURES.STUDIO);

    constructor(
        private bannerSetService: BannerSetService,
        private sessionService: SessionService,
        private listService: ListService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.BannerSet;

        this.tableRows = new Array<ExObjectItem<BannerSet>>();
    }

    protected async loadItems(): Promise<void> {
        this.currentFolder = this.currentFolder || this.folder;

        this.loading = true;

        const query: ExObjectQuery = this.getSearchQuery();
        query.onlyStudio = this.onlyStudio;
        /*
         *  TODO: This is not the optimal solution. But it was the best I could come up with on a short time period.
         *  Both backend and front end should be able to handle that amount though.
         *  We need to find a directive than ininiteScroll.directive.ts that works on all types of scrolling down. Probably done in the upcoming Bannerflow UI
         */
        query.count = 1000;

        if (!this.showOnlyBannerSets) {
            this.handleCreatives();
        } else if (this.hasStudioAccess || this.showOnlyBannerSets) {
            this.bannerSetService
                .getBannerSetsEx(query, !this.showOnlyBannerSets)
                .then((result: ExObjectResult<BannerSet>) => {
                    this.handleResponse(result);
                });
        }
    }

    public onTableClick(tableEvent: any): void {
        const row: CreativeSetListItem = tableEvent.row;
        const item: ExObjectItem<CreativeSetListItem> = this.getRowValue(row);
        const isCreativeSet = item?.data.type === ListItemType.CreativeSet;

        if (tableEvent.type === 'checkbox') {
            return;
        }
        const isValidClick = tableEvent.type === 'click' || tableEvent.type === 'dblclick';

        if (!isValidClick) {
            return;
        }

        if (isCreativeSet) {
            this.selectCreativeSet(item, 'externalId');
        } else {
            this.selectFolder(item.data.id);
        }

        // B2
        const itemB2: ExObjectItem<BannerSet> = tableEvent.row;
        const isB2CreativeSet = !itemB2.isFolder && this.showOnlyBannerSets;

        if (isB2CreativeSet) {
            this.selectCreativeSet(item);
        } else {
            this.selectFolder(item.data.id);
        }
    }

    private handleCreatives(): void {
        if (this.searchQuery?.length > 0) {
            this.listService
                .getFilteredCreativesetAndFolders({ name: this.searchQuery }, 1, 250, {
                    fieldName: 'name',
                    order: 'asc'
                })
                .subscribe(result => this.handleCommonListViewResponse(result));
        } else {
            this.listService
                .getCreativesetsandFolders(
                    1,
                    250,
                    this.currentFolder ? this.currentFolder : '',
                    {
                        fieldName: 'name',
                        order: 'asc'
                    },
                    this.brandId
                )
                .pipe(shareReplay())
                .subscribe(result => this.handleCommonListViewResponse(result));
        }
    }

    private selectCreativeSet(item: ExObjectItem<CreativeSetListItem | BannerSet>, selectedKey = 'id'): void {
        if (this.multi) {
            // @ts-ignore
            const selectedId = item.data[selectedKey];
            this.toggleItem(selectedId);
            this.selectedChange.emit(this.selected);
        } else {
            this.selectedChange.emit(item.data);
        }
    }

    private selectFolder(folderId: string): void {
        this.initialLoad = true;
        this.currentFolder = folderId;
        this.loadItems();
    }

    private handleCommonListViewResponse(result: CreativeSetTableData): void {
        this.tableRows = result.items;
        super.loadItems('externalId');

        this.breadcrumbs = result.path.length ? result.path : [];

        if (this.breadcrumbs.length === 1) {
            this.breadcrumbs.push({ name: null, id: null });
        } else if (this.breadcrumbs.length > 1) {
            this.breadcrumbs.pop();
        }

        setTimeout(() => {
            this.datatable.recalculate();
        });
        this.loading = false;
    }

    private handleResponse(result: ExObjectResult<BannerSet>): void {
        let bannerSets: Array<ExObjectItem<BannerSet>> = result.items;
        if (result.items) {
            // re-map bannerset ids
            bannerSets = bannerSets.map(item => {
                if (!item.isFolder && item.data.creativesetId) {
                    item.data.id = item.data.creativesetId;
                }

                return item;
            });
        }

        this.tableRows = bannerSets;

        this.breadcrumbs = result.breadcrumbs;
        this.hasMore = result.hasMore;

        super.loadItems();

        setTimeout(() => {
            this.datatable.recalculate();
        });

        this.loading = false;
        this.initialLoad = false;
    }

    public async loadMore(): Promise<void> {
        if (this.loading || !this.hasMore) {
            return;
        }

        this.currentPage += 1;
        this.currentCount = this.currentPage * this.PAGE_SIZE;

        await this.loadItems();
    }
}
