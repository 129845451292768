export enum PermissionRole {
    PERMISSION_ROLE_NONE = 0,
    PERMISSION_ROLE_USER = 1,
    PERMISSION_ROLE_ADMIN = 2,
    PERMISSION_ROLE_OWNER = 3,
    PERMISSION_ROLE_EXTERNAL_USER = 5,
    PERMISSION_ROLE_TEXT_EDITOR = 6,
    PERMISSION_ROLE_DESIGNER = 7,
    PERMISSION_ROLE_TEXT_EDITOR_EXTENDED = 8,
    PERMISSION_ROLE_EXTERNAL_DESIGNER = 9,
    PERMISSION_ROLE_PUBLISHER = 10,
    PERMISSION_ROLE_ANALYZER = 11,
    PERMISSION_ROLE_TEXT_EDITOR_WITH_ANALYZER = 12,
    PERMISSION_ROLE_PUBLISHER_EXTENDED = 13
}
