import { Component, ElementRef, Renderer2 } from '@angular/core';
import { LocationService } from '@core/services/internal/location.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'countryPicker',
    templateUrl: 'country.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, UIModule, NgxDatatableModule]
})
export class CountryPickerComponent extends ObjectPickerComponent {
    constructor(elementRef: ElementRef, renderer: Renderer2, private locationService: LocationService) {
        super(elementRef, renderer);
        this.type = ObjectType.Country;

        this.tableRows = new Array<BannerFormat>();
    }

    protected loadItems(): void {
        this.tableRows = this.locationService.countryList.map((country: any) => {
            return { id: country.countryCode, name: country.name };
        });

        super.loadItems();
    }
}
