<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search size format"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery" />
    </div>
    <div class="objectPicker__table">
        <!-- TODO!! check this loader -->
        <ui-loader *ngIf="initialLoad && loading"></ui-loader>
        <ngx-datatable
            #datatable
            [rows]="tableRows"
            [columnMode]="'force'"
            [rowHeight]="40"
            (activate)="onTableClick($event)"
            [selected]="selectedObjects"
            (selectedChange)="selectedObjects = $event"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            [selectCheck]="canSelect">
            <ngx-datatable-column
                *ngIf="multi"
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"></ngx-datatable-column>
            <ngx-datatable-column
                name="Width"
                prop="size.width">
                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template>
                    {{ row.size.width }}x{{ row.size.height }}
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
