import { Commentable } from '@shared/interfaces/commentable.interface';
import { Serializable } from '@shared/interfaces/serializable.interface';
import { Comment } from '../comment.model';
import { Version } from '../version/version.model';
import { AdType } from './adType.model';
import { BannerFormat } from './bannerFormat.model';
import { SizeFormat } from './sizeFormat.model';

export class Banner implements Commentable, Serializable<Banner> {
    public id: string;
    public brandId: string;
    public sizeFormat: SizeFormat;
    public bannerFormat: BannerFormat;
    public adType: AdType;
    public version: Version;
    public previewBanner: string;
    public previewImage: string;
    public previewScript: string;
    public fileSize: number;
    public imageFileSize: number;
    public targetUrl: string;
    public landingPageUrl: string;

    public comments: Comment[];
    public approved: Comment;
    public placementScript: string;

    public deserialize(json: any): Banner {
        if (!json) return this;

        this.id = json.id;
        this.bannerFormat = new BannerFormat().deserialize(json.bannerFormat);
        this.adType = new AdType().deserialize(json.adType);
        this.previewScript = json.previewUrl || json.previewScript;
        this.brandId = json.brandId;
        this.version = new Version().deserialize(json.version);
        this.comments = (json.comments || []).map((comment: any) => {
            return new Comment().deserialize(comment);
        });
        this.targetUrl = json.targetUrl;
        this.landingPageUrl = json.landingPageUrl;

        return this;
    }
}
