import { Component, EventEmitter, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/clickOutside.directive';

@Component({
    selector: 'bfDropDown',
    styleUrls: ['./bfDropDown.component.scss'],
    templateUrl: 'bfDropDown.component.html',
    standalone: true,
    imports: [NgClass, ClickOutsideDirective, NgIf]
})
export class BFDropDownComponent {
    @Output('open') private openEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output('close') private closeEvent: EventEmitter<any> = new EventEmitter<any>();
    // @Input('contentStyleTop') private contentStyleTop: string = '100%';
    public collapsed = true;

    private clickEventListener: Function;

    public toggleCollapsed(toggle: boolean = undefined): void {
        if (toggle === undefined) {
            this.collapsed = !this.collapsed;
        } else {
            this.collapsed = toggle;
        }

        if (this.clickEventListener) {
            this.clickEventListener();
        }

        if (!this.collapsed) {
            // Dispatch open event
            this.openEvent.emit();
        } else {
            // Dispatch close event
            this.closeEvent.emit();
        }
    }
}

export class BFDropDownConfig {
    constructor(public maxWidth: number = 0) {}
}
