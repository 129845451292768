<div
    class="profileImage"
    [ngStyle]="{ backgroundColor: userColor }"
    [ngClass]="{
        'profileImage--hasArrow': showArrow,
        'profileImage-small': small
    }">
    {{ initials }}
    <div
        class="profileImage-arrow"
        [ngStyle]="{ borderTopColor: userColor }"
        *ngIf="showArrow"></div>
</div>
