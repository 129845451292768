import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsBusService {
    private _resetFilterEvent = new BehaviorSubject<unknown>(null);

    public get resetFilterListener(): Observable<unknown> {
        return this._resetFilterEvent.asObservable();
    }

    public emitChildEvent(msg: unknown): void {
        this._resetFilterEvent.next(msg);
    }
}
