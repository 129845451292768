import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { LocalizationService } from '@core/services/api/bannerflow/localization.service';
import { VersionService } from '@core/services/api/bannerflow/version.service';
import { NavigatorService, ObjectWithType } from '@core/services/internal/navigator.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { CreativeVersion } from '@shared/models/studio/creative-version.model';
import { CreativeSet } from '@shared/models/studio/creativeset.model';
import { Localization } from '@shared/models/version/localization.model';
import { Version } from '@shared/models/version/version.model';
import { firstValueFrom } from 'rxjs';
import { ObjectPickerComponent } from '../objectPicker.component';
import { AnalyticsApiService } from '@analytics/services/analyticsApi.service';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'versionPicker',
    templateUrl: 'version.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, UIModule, NgxDatatableModule]
})
export class VersionPickerComponent extends ObjectPickerComponent {
    @Input() public bannerSetId: string;

    constructor(
        private versionService: VersionService,
        private navigatorService: NavigatorService,
        private localizationService: LocalizationService,
        private analyticsApiService: AnalyticsApiService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.Localization;

        this.tableRows = new Array<Version>();
    }

    protected loadItems(): void {
        this.loading = true;

        this.getVersions().then(() => {
            super.loadItems();
            this.datatable.recalculate();
            this.loading = false;
        });
    }

    public async getVersions(): Promise<void> {
        const currentObject: ObjectWithType = await this.navigatorService.getCurrentObject();
        if (currentObject.objectType === ObjectType.StudioCreativeSet) {
            const creativeSets: CreativeSet[] = await this.analyticsApiService.getCreativeSetsByIds([this.bannerSetId]);

            if (creativeSets[0]) {
                const studioVersions: CreativeVersion[] = creativeSets[0].versions;
                const localizations: Localization[] = await firstValueFrom(this.localizationService.getLocalizations());

                // HACK: this ugly hack override is required for template
                // goddamn it!
                studioVersions.forEach((version: any) => {
                    version.localization = localizations.find(
                        localization => localization.id === version.localization.id
                    );
                });

                this.tableRows = studioVersions;
            }
        } else {
            const versions: Version[] = await this.versionService.getVersions(this.bannerSetId);
            this.tableRows = versions;
        }
    }
}
