<div
    class="objectDropDown"
    [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown
        (open)="onOpen()"
        (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
        </div>
        <div bfDropDownContent>
            <brandPicker
                [multi]="multi"
                [(selected)]="selected"
                (selectedChange)="change($event)"
                [placeholder]="placeholder"
                [allowNoneSelection]="allowNoneSelection"
                *ngIf="isOpen"></brandPicker>
        </div>
    </bfDropDown>
</div>
