import { Component, Input } from '@angular/core';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass, NgIf } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { CountryPickerComponent } from './country.picker.component';

@Component({
    selector: 'countryDropDown',
    templateUrl: 'country.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    standalone: true,
    imports: [NgClass, BFDropDownComponent, NgIf, CountryPickerComponent]
})
export class CountryDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No country selected';
}
