import { Injectable } from '@angular/core';
import { SizeFormat } from '@shared/models/banner/sizeFormat.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class SizeFormatService {
    private sizeFormatPromise: Promise<SizeFormat[]>;

    constructor(private readonly apiService: ApiService) {}

    public getSizeFormats(): Promise<SizeFormat[]> {
        // If brands already fetched once, return them
        if (this.sizeFormatPromise) return this.sizeFormatPromise;

        // Fetch brands and store result in brand promise for later use
        this.sizeFormatPromise = new Promise<SizeFormat[]>(resolve => {
            this.apiService
                .get('/api/v1/b/[accountSlug]/[brandSlug]/sizeformats', { anonymous: true })
                .then((data: any) => {
                    const sizeFormats = data.map((sizeFormat: any) => {
                        return new SizeFormat().deserialize(sizeFormat);
                    });

                    resolve(sizeFormats);
                });
        });

        return this.sizeFormatPromise;
    }
}
