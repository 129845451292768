import { NgModule } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
    imports: [
        StoreModule.forRoot(),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: AppConfig.config.STAGE === 'production' // Restrict extension to log-only mode
            // autoPause: true // Pauses recording actions and state changes when the extension window is not open
        })
    ]
})
export class AppStoreModule {}
