<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search creative set"
            spellcheck="false"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery" />
    </div>
    <div
        class="objectPicker__goUp"
        *ngIf="breadcrumbs && breadcrumbs.length"
        (click)="goUp()">
        <i
            aria-hidden="true"
            class="folderIcon folderIcon--symbol bf-icon bf-icon-arrow-left-a"></i>
        Go back
    </div>
    <div class="objectPicker__table">
        <ui-loader *ngIf="loading"></ui-loader>
        <ngx-datatable
            #datatable
            [class.loading-hidden]="loading"
            [selected]="selectedObjects"
            [columnMode]="'force'"
            [messages]="{ emptyMessage: 'No matches for your search' }"
            [rowHeight]="40"
            [rows]="tableRows"
            [selectCheck]="canSelect"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            (activate)="onTableClick($event)"
            (selectedChange)="selectedObjects = $event">
            <ngx-datatable-column
                *ngIf="multi"
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true">
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Name"
                prop="data.name">
                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template>
                    <span
                        [ngClass]="{
                            'live-bannerset': row.data && row.data.impressionsLastWeek > 100 && !row.isFolder,
                            'live-folder': row.data && row.data.impressionsLastWeek > 100 && row.isFolder
                        }">
                        <i
                            aria-hidden="true"
                            class="itemIcon itemIcon--folder bf-icon bf-icon-folder-o"
                            *ngIf="row.isFolder || row.type === 'Folder'">
                            <!-- After a discussion, we came up with this extremly ugly solution. This will be replaced
                            when the user can actually check a whole folder at a time. -->
                            <div class="hidecheckbox"></div>
                        </i>
                        <i
                            aria-hidden="true"
                            class="itemIcon bf-icon bf-icon-banner-set"
                            *ngIf="!row.isFolder || row.type === 'CreativeSet'"></i>
                        <span *ngIf="showOnlyBannerSets; else showName">
                            {{ value }}
                        </span>
                        <ng-template #showName>
                            <span>
                                {{ row.name }}
                            </span>
                        </ng-template>
                        <div
                            class="objectPicker__statusSign objectPicker__banners"
                            *ngIf="!row.isFolder && showAmountOfBanners">
                            Contains {{ row.data.banners }} banners
                        </div>
                        <i
                            aria-hidden="true"
                            class="itemIcon itemIcon--arrow bf-icon bf-icon-arrow-right-b"
                            *ngIf="row.isFolder || row.type === 'Folder'"></i>
                        <div
                            *ngIf="row.data && row.data.impressionsLastWeek > 1000 && !row.isFolder"
                            class="objectPicker__statusSign"
                            uiTooltip="This bannerset has received over 1,000 impressions the last 7 days ({{
                                row.data.impressionsLastWeek | number
                            }}). This is updated once a day.">
                            Live
                        </div>
                        <div
                            *ngIf="
                                row.data &&
                                row.data.impressionsLastWeek > 1000 &&
                                (row.isFolder || row.type === 'Folder')
                            "
                            class="objectPicker__statusSign objectPicker__statusSign--folder"
                            uiTooltip="Bannersets in this folder have received over 1,000 impressions combined in the last 7 days ({{
                                row.data.impressionsLastWeek | number
                            }}). This is updated once a day.">
                            Live
                        </div>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <ui-loader *ngIf="loading"></ui-loader>
    </div>
</div>
