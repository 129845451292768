<div
    class="objectDropDown"
    [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown
        (open)="onOpen()"
        (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
            <i
                aria-hidden="true"
                class="objectDropDown__clear bf-icon bf-icon-close-filled"
                (click)="clear($event)"
                *ngIf="multi && selected.length"></i>
        </div>
        <div bfDropDownContent>
            <bannerSetPicker
                #objectPicker
                [multi]="multi"
                [onlyStudio]="onlyStudio"
                [(selected)]="selected"
                (selectedChange)="change($event)"
                *ngIf="isOpen"></bannerSetPicker>
        </div>
    </bfDropDown>
</div>
