import { Serializable } from '@shared/interfaces/serializable.interface';
import { AdType } from './adType.model';
import { SizeFormat } from './sizeFormat.model';

export class BannerFormat implements Serializable<BannerFormat> {
    public id: string;
    public name: string;
    public sizeFormat: SizeFormat;
    public fileSize: number;
    public fileImageSize: number;
    public adType: AdType;
    public loops: number;
    public duration: string;
    public isFeeded: boolean;

    public deserialize(json: any): BannerFormat {
        if (!json) return this;

        this.id = json.id;
        this.name = json.name;
        this.sizeFormat = new SizeFormat().deserialize(json.sizeFormat);
        this.adType = new AdType().deserialize(json.adType);
        this.isFeeded = json.isFeeded;

        return this;
        // TODO: Complete deserialization
    }
}
