import { IPublishDestination } from '../api/campaign.interface';
import { Model } from '../lib/model';

export class PublishDestination extends Model<PublishDestination> implements IPublishDestination {
    public id: string;
    public name: string;
    public icon: string;

    public deserialize(json: any): PublishDestination {
        if (!json) {
            return this;
        }

        this.id = json.id;
        this.name = json.name;
        this.icon = json.icon;

        return this;
    }
}
