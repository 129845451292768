import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IRadioGroupOptions } from '@shared/interfaces/radio-group-options.interface';
import { UIModule } from '@bannerflow/ui';
import { NgFor } from '@angular/common';

@Component({
    selector: 'radio-group',
    templateUrl: 'radio-group.component.html',
    styleUrls: ['radio-group.component.scss'],
    standalone: true,
    imports: [UIModule, NgFor]
})
export class RadioGroupComponent {
    @Input() public label?: string = '';
    @Input() public options: IRadioGroupOptions[] = [];
    @Input() public selected: string | number;
    @Output() private selectedChange = new EventEmitter<any>();

    public updateSelected(): void {
        this.selectedChange.emit(this.selected);
    }
}
