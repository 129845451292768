import { Injectable, ErrorHandler, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { AppConfig } from '@config/app.config';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    sentinelService = inject(SentinelService);

    handleError(error: Error | Event | string): void {
        AppConfig.config.STAGE === 'production' || AppConfig.config.STAGE === 'sandbox'
            ? this.handleProductionError(error)
            : this.handleDevelopmentError(error);
    }

    private handleProductionError(error: Error | Event | string): void {
        this.sentinelService.error(error);
    }

    private handleDevelopmentError(error: Error | Event | string): void {
        console.error('An error occurred:', error);
    }
}
