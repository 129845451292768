<div
    class="comments__input comments__input--noEmail"
    *ngIf="isEditingEmail || (anonymous && !sessionService.user.email)">
    <div
        *ngIf="!isEditingEmail"
        class="small_gap">
        Enter your email address to comment!
    </div>
    <ui-input
        style="float: left; width: 50%"
        #commentEmailInput
        (keydown)="setEmail($event)"
        [(value)]="email"
        placeholder="Your email...">
    </ui-input>
    <ui-button
        style="margin-left: 1rem"
        type="default"
        text="next"
        (click)="setEmail($event)"
        [disabled]="!validEmail(email)"></ui-button>
    <div>
        <small
            class="comments__input__error"
            *ngIf="emailError"
            >You must enter a valid email address</small
        >
    </div>
</div>

<div
    class="comments__input"
    *ngIf="!anonymous">
    <div class="comments__userImage"></div>
    <div
        *ngIf="!isReply"
        class="comments__userName"
        [uiTooltipInteractive]="true"
        uiTooltip="{{ sessionService.user.email }}">
        <span class="trunc"
            >Commenting as <strong>{{ sessionService.user.firstName }} {{ sessionService.user.lastName }}</strong></span
        >
    </div>
    <textarea
        #commentInput
        (keydown)="addComment($event)"
        [(ngModel)]="message"
        [placeholder]="isReply ? 'Type your reply here...' : 'Type your comment here...'"
        class="comments__textbox"
        spellcheck="false">
    </textarea>
    <ui-button
        style="margin-top: 1rem"
        type="default"
        text="post comment"
        (click)="addComment($event)"
        [disabled]="!message"></ui-button>
</div>

<div
    class="comments__input"
    *ngIf="anonymous && sessionService.user.email">
    <div class="comments__userImage"></div>
    <div
        *ngIf="!isReply && !isEditingEmail"
        class="comments__userName"
        (click)="clearEmail($event)"
        [uiTooltipInteractive]="true"
        uiTooltip="{{ sessionService.user.email }}">
        <span>
            <span class="trunc"
                >Commenting as <strong>{{ sessionService.user.email }}</strong></span
            >
            <i
                aria-hidden="true"
                class="comments__editIcon bf-icon bf-icon-edit"></i>
        </span>
    </div>
    <textarea
        #commentInput
        (keydown)="addComment($event)"
        [(ngModel)]="message"
        [placeholder]="'Type your ' + (isReply ? 'reply ' : 'comment') + ' here...'"
        class="comments__textbox"
        spellcheck="false">
    </textarea>
    <ui-button
        style="margin-top: 1rem"
        type="default"
        text="post comment"
        (click)="addComment($event)"
        [disabled]="!message"></ui-button>
</div>
