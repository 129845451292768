import { Injectable } from '@angular/core';
import { UIErrorDialogService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { UserTitle } from '@shared/models/usertitle.model';
import { ApiService, BFHttpError } from '../api.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(
        private readonly apiService: ApiService,
        private readonly errorDialogService: UIErrorDialogService,
        private readonly authService: AuthService,
    ) {}

    private getReadApiPrefix(): string {
        return `${AppConfig.config.ACCOUNT_ACCESS_URL}/api`;
    }
    protected async setupHeaders(): Promise<HttpHeaders> {
      const token: string = await firstValueFrom(this.authService.getAccessTokenSilently());
      const headers: HttpHeaders = new HttpHeaders({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
      });

      return headers;
    }

    public async getUserTitle(userId: string): Promise<UserTitle> {
      const headers: HttpHeaders = await this.setupHeaders();
        try {
            return this.apiService
                .get(`${this.getReadApiPrefix()}/profiles/${userId}`, {
                    anonymous: true,
                    headers
                })
                .then(userprofile => userprofile.userTitle);
        } catch (error) {
            return this.handleError(error);
        }
    }

    private async handleError(error: any): Promise<never> {
        if (error instanceof BFHttpError) {
            if (error.status === 404) {
                throw new Error('404 missing resource');
            } else {
                this.errorDialogService.show(
                    {
                        headerText: 'An error has occurred',
                        confirmText: 'Close'
                    },
                    error
                );
            }
        }

        return Promise.reject(error);
    }
}
