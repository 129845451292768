import { Commentable } from '@shared/interfaces/commentable.interface';
import { Serializable } from '@shared/interfaces/serializable.interface';
import { Comment } from '../comment.model';
import { Owner } from '../owner.model';

export class BannerSet implements Commentable, Serializable<BannerSet> {
    public id: string;
    public slug: string;
    public name: string;
    public thumb: string;
    public publicId: string;
    public isDone: boolean;
    public isShowcasePasswordSet: string;
    public comments: Comment[] = [];
    public totalComments = 0;
    public created: Date;
    public modified: Date;
    public createdBy: Owner;
    public folder: string;
    public impressionsLastWeek: number;
    public banners: number;
    public creativesetId: string;

    public deserialize(json: any): BannerSet {
        if (!json) {
            return null;
        }

        this.id = json.id;
        this.slug = json.slug;
        this.name = json.name;
        this.thumb = json.thumb;
        this.publicId = json.publicId;
        this.isDone = json.isDone;
        this.isShowcasePasswordSet = json.isShowcasePasswordSet;
        this.created = json.created;
        this.modified = json.modified;
        this.folder = json.folder;
        this.totalComments = json.totalComments;
        this.impressionsLastWeek = json.impressionsLastWeek;
        this.banners = json.banners;
        this.creativesetId = json.creativesetId;

        return this;
    }
}
