import { Injectable } from '@angular/core';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class BannerFormatService {

    constructor(private readonly apiService: ApiService) {}

    public getBannerFormats(bannerSetId: string): Promise<BannerFormat[]> {
        // Fetch brands and store result in brand promise for later use
        return this.apiService.get(`bannersets/${bannerSetId}/bannerformats`).then((data: any) => {
            const versions = data.map((bannerFormat: any) => {
                return new BannerFormat().deserialize(bannerFormat);
            });

            return Promise.resolve(versions);
        });
    }
}
