import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AppService } from '@core/services/internal/app.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { InitializationService } from '@core/services/internal/initialization.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SidebarService } from '@core/services/internal/sidebar.service';
import { NotificationInfo, StickyNotificationService } from '@core/services/internal/sticky-notification.service';
import { Observable } from 'rxjs';
import { UIModule } from '@bannerflow/ui';
import { HeaderContentComponent } from './shared/components/headerContent/headerContent.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { WelcomePanelComponent } from './shared/components/welcomePanel/welcomePanel.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';

@Component({
    selector: 'mainView',
    templateUrl: 'app.view.component.html',
    styleUrls: ['app.view.component.scss'],
    standalone: true,
    imports: [UIModule, HeaderContentComponent, NgIf, NgClass, WelcomePanelComponent, SidebarComponent, RouterOutlet, AsyncPipe]
})
export class AppViewComponent implements OnInit {
    isExpanded: boolean;
    stickyNotification$: Observable<NotificationInfo>;

    constructor(
        public appService: AppService,
        private activatedRoute: ActivatedRoute,
        private navigatorService: NavigatorService,
        private sidebarService: SidebarService,
        private stickyNotificationService: StickyNotificationService,
        private initializationService: InitializationService
    ) {
        this.navigatorService.rootRoute = this.activatedRoute;
        this.stickyNotification$ = this.stickyNotificationService.notificationInfo$;
        this.appService.setIsInitialized();
        this.sidebarService
            .isExpanded()
            .pipe(takeUntilDestroyed())
            .subscribe((isExpanded: boolean) => (this.isExpanded = isExpanded));
    }

    async ngOnInit(): Promise<void> {
        await this.initializationService.initialize();
    }

    public onLogoClick(): void {
        CookieService.set('newLogin', 'true');
        this.navigatorService.goHome();
    }
}
