import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { SessionService } from '@core/services/internal/session.service';

type FeatureData = {
    data: {
        requiredFeatures: string[];
        allMustMatch: boolean;
    };
};

@Injectable({
    providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
    constructor(private sessionService: SessionService) {}

    public async canActivate(route: ActivatedRouteSnapshot & FeatureData): Promise<boolean> {
        // user needs to be loaded in order for sessionService to evaluate features
        const user = await this.sessionService.getUserByActivatedRouteSnapshot(route);
        const accountSlug: string = user.brand.accountSlug;
        const brandSlug: string = user.brand.name;
        const routeDestination = `/${accountSlug}/${brandSlug}`;

        const hasFeatures: boolean = this.sessionService.hasFeatures(
            route.data.requiredFeatures,
            route.data.allMustMatch
        );
        if (hasFeatures) {
            return true;
        }

        // Here we use vanilla js instead of angular-navigation since we want to route out from angular -> angularJS
        window.location.assign(routeDestination);

        return false;
    }
}
