import { IAppConfig } from './app.config.type';
import { config } from './environment.dev';

export class AppConfig {
    private static _config: IAppConfig;

    public static get config(): IAppConfig {
        this._config = Object.freeze(config);
        return this._config;
    }
}
