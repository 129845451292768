import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ScheduleService } from '@core/services/api/bannerflow/schedule.service';
import { ExObjectItem, ExObjectResult } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { Schedule } from '@shared/models/schedule.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UIModule } from '@bannerflow/ui';
@Component({
    selector: 'schedulePicker',
    templateUrl: 'schedule.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, NgxDatatableModule, UIModule]
})
export class SchedulePickerComponent extends ObjectPickerComponent {
    constructor(private scheduleService: ScheduleService, elementRef: ElementRef, renderer: Renderer2) {
        super(elementRef, renderer);
        this.type = ObjectType.BannerSet;

        this.tableRows = new Array<ExObjectItem<BannerSet>>();
    }

    public loadMore(): void {
        if (this.loading || !this.hasMore) return;

        this.currentPage += 1;
        this.currentCount = this.currentPage * this.PAGE_SIZE;

        this.loadItems();
    }

    protected loadItems(): void {
        this.currentFolder = this.currentFolder || this.folder;

        this.loading = true;

        const query = this.getSearchQuery();

        this.scheduleService.getSchedulesEx(query).then((result: ExObjectResult<Schedule>) => {
            this.tableRows = result.items;
            this.breadcrumbs = result.breadcrumbs;
            this.hasMore = result.hasMore;

            super.loadItems();

            setTimeout(() => {
                this.datatable.recalculate();
            });

            this.loading = false;
            this.initialLoad = false;
        });
    }
}
