import { Serializable } from '@shared/interfaces/serializable.interface';
import { Localization } from './localization.model';

export class Version implements Serializable<Version> {
    public id: string;
    public name: string;
    public localization: Localization;
    public targetUrl: string;
    public landingPageUrl: string;
    public texts: object[];

    public deserialize(json: any): Version {
        if (!json) return this;

        this.id = json.id;
        this.name = json.name;
        this.localization = new Localization().deserialize(json.localization);
        this.targetUrl = json.targetUrl;
        this.landingPageUrl = json.landingPageURl;

        return this;
    }
}
