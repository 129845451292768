import { Component, OnDestroy } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { AppService } from '@core/services/internal/app.service';
import { HistoryService } from '@core/services/internal/history.service';
import { NavigatorService } from '@core/services/internal/navigator.service';
import { SessionService } from '@core/services/internal/session.service';
import { LocalSettingsService } from '@core/services/internal/settings.service';
import { SidebarService } from '@core/services/internal/sidebar.service';
import { Observable } from 'rxjs';
import { NgClass, NgIf, NgFor, NgStyle, AsyncPipe, DecimalPipe } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';

@Component({
    selector: 'welcomePanel',
    styleUrls: ['./welcomePanel.component.scss'],
    templateUrl: 'welcomePanel.component.html',
    standalone: true,
    imports: [NgClass, UIModule, NgIf, NgFor, NgStyle, AsyncPipe, DecimalPipe, EllipsisPipe]
})
export class WelcomePanelComponent implements OnDestroy {
    public visitedObjects: any[];
    public placeholders: any[];

    public showOnLogin = false;
    public visible: boolean;
    public isSidebarExpanded: Observable<boolean>;

    private welcomePanelVisibleSubscription: any;
    private navigationStartSubscription: any;

    constructor(
        public sessionService: SessionService,
        private historyService: HistoryService,
        private navigatorService: NavigatorService,
        private appService: AppService,
        private sidebarService: SidebarService
    ) {
        this.visitedObjects = this.historyService.getVisitedObjects();
        this.placeholders = this.getPlaceholders();
        this.showOnLogin = LocalSettingsService.get<boolean>('dontShowWelcome') ?? false;

        this.welcomePanelVisibleSubscription = this.appService.welcomePanelToggled.subscribe((visible: boolean) => {
            this.visible = visible;
        });

        this.navigationStartSubscription = this.navigatorService.navigationStart.subscribe(() => {
            this.visitedObjects = this.historyService.getVisitedObjects();
            this.placeholders = this.getPlaceholders();
        });

        this.isSidebarExpanded = this.sidebarService.isExpanded();
    }

    public showOnLoginChange(): void {
        LocalSettingsService.set<boolean>('dontShowWelcome', this.showOnLogin);
    }

    public gotoObject(object: any): void {
        switch (object.type) {
            case 'bannerset':
                this.sessionService.gotoA1Url(`BannerSets/c/0?id=${object.id}`);
                break;
            case 'creativeset':
                window.open(
                    `${AppConfig.config.STUDIO_URL}/brand/${this.sessionService.user.brand.id}/creativeset/${object.id}`,
                    '_blank'
                );
                break;
            case 'feed':
                this.sessionService.gotoA1Url(`Feeds/c/${object.id}`);
                break;
            case 'schedule':
                this.sessionService.gotoA1Url(`Schedules/c/0?id=${object.id}`);
                break;
            case 'landingpage':
                this.navigatorService.go(['landingpages', object.id]);
                break;
            case 'campaign':
                window.open(
                    `${AppConfig.config.CAMPAIGN_MANAGER_URL}/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/campaign/${object.id}`,
                    '_blank'
                );
                break;
            default:
                break;
        }
    }

    private getPlaceholders(): any[] {
        const placeholders: any[] = [];
        for (let i = 0; i < 5 - this.visitedObjects.length; i++) {
            placeholders.push({ index: i });
        }

        return placeholders;
    }

    ngOnDestroy(): void {
        if (this.welcomePanelVisibleSubscription) {
            this.welcomePanelVisibleSubscription.unsubscribe();
        }
        if (this.navigationStartSubscription) {
            this.navigationStartSubscription.unsubscribe();
        }
    }
}
