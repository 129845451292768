import { CommonModule, DatePipe, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BannerComponent } from './components/banner/banner.component';
import { ApproveDialogComponent } from './components/comment/approveDialog/approveDialog.component';
import { CommentInputComponent } from './components/comment/commentInput.component';
import { CommentsComponent } from './components/comment/comments.component';
import { CommentsService } from './components/comment/comments.service';
import { BFDropDownComponent } from './components/dropDown/bfDropDown.component';
import { HeaderContentBrandPickerComponent } from './components/headerContent/brandPicker/brandPicker.component';
import { HeaderContentComponent } from './components/headerContent/headerContent.component';
import { AdGroupDropDownComponent } from './components/objectPicker/ad-group/adgroup.dropdown.component';
import { AdGroupPickerComponent } from './components/objectPicker/ad-group/adgroup.picker.component';
import { BannerFormatDropDownComponent } from './components/objectPicker/bannerformat/bannerformat.dropdown.component';
import { BannerFormatPickerComponent } from './components/objectPicker/bannerformat/bannerformat.picker.component';
import { BannerSetDropDownComponent } from './components/objectPicker/bannerset/bannerset.dropdown.component';
import { BannerSetPickerComponent } from './components/objectPicker/bannerset/bannerset.picker.component';
import { BrandDropDownComponent } from './components/objectPicker/brand/brand.dropdown.component';
import { BrandPickerComponent } from './components/objectPicker/brand/brand.picker.component';
import { CampaignDropDownComponent } from './components/objectPicker/campaign/campaign.dropdown.component';
import { CampaignPickerComponent } from './components/objectPicker/campaign/campaign.picker.component';
import { CountryDropDownComponent } from './components/objectPicker/country/country.dropdown.component';
import { CountryPickerComponent } from './components/objectPicker/country/country.picker.component';
import { LocalizationDropDownComponent } from './components/objectPicker/localization/localization.dropdown.component';
import { LocalizationPickerComponent } from './components/objectPicker/localization/localization.picker.component';
import { ObjectDropDownComponent } from './components/objectPicker/objectDropDown.component';
import { ObjectPickerComponent } from './components/objectPicker/objectPicker.component';
import { ScheduleDropDownComponent } from './components/objectPicker/schedule/schedule.dropdown.component';
import { SchedulePickerComponent } from './components/objectPicker/schedule/schedule.picker.component';
import { SizeFormatDropDownComponent } from './components/objectPicker/sizeformat/sizeformat.dropdown.component';
import { SizeFormatPickerComponent } from './components/objectPicker/sizeformat/sizeformat.picker.component';
import { VersionDropDownComponent } from './components/objectPicker/version/version.dropdown.component';
import { VersionPickerComponent } from './components/objectPicker/version/version.picker.component';
import { ProfileImageComponent } from './components/profileImage/profileImage.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { BFSelectableComponent } from './components/selectableList/bfSelectable.component';
import { BFSelectableDirective } from './components/selectableList/bfSelectable.directive';
import { BFSelectableListComponent } from './components/selectableList/bfSelectableList.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { DebounceInputDirective } from './directives/debounceInput.directive';
import { InViewDirective } from './directives/inView.directive';
import { NavigatorLinkDirective } from './directives/navigatorLink.directive';
import { NumbersOnlyDirective } from './directives/numbersOnly.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { MapPropertyPipe } from './pipes/mapProperty.pipe';
import { ObjectSearchPipe } from './pipes/objectSearch.pipe';
import { ToSecondsPipe } from './pipes/toSeconds.pipe';
import { ToSentencePipe } from './pipes/toSentence.pipe';
import { ToTimeAgoPipe } from './pipes/toTimeAgo.pipe';
import { UrlToDomainPipe } from './pipes/urlToDomain.pipe';
import { EnvironmentPickerComponent } from './components/headerContent/environment-picker/environment-picker.component';
import { ReportingLabelComponent } from './components/objectPicker/reporting-label/reporting.label.component';
import { ReportingPickerComponent } from './components/objectPicker/reporting-label/reprting.label.picker.component';
import { WelcomePanelComponent } from './components/welcomePanel/welcomePanel.component';

@NgModule({
    imports: [CommonModule, UIModule, FormsModule, RouterModule, NgxDatatableModule, EllipsisPipe, 
        // COMPONENTS
        ApproveDialogComponent,
        BFDropDownComponent,
        BannerComponent,
        BannerFormatDropDownComponent,
        BannerFormatPickerComponent,
        BannerSetDropDownComponent,
        BannerSetPickerComponent,
        BFSelectableComponent,
        BFSelectableDirective,
        BFSelectableListComponent,
        BrandDropDownComponent,
        BrandPickerComponent,
        CampaignPickerComponent,
        CampaignDropDownComponent,
        CommentInputComponent,
        CommentsComponent,
        CountryDropDownComponent,
        CountryPickerComponent,
        AdGroupPickerComponent,
        AdGroupDropDownComponent,
        HeaderContentComponent,
        HeaderContentBrandPickerComponent,
        LocalizationDropDownComponent,
        LocalizationPickerComponent,
        ObjectDropDownComponent,
        ObjectPickerComponent,
        ProfileImageComponent,
        RadioGroupComponent,
        ScheduleDropDownComponent,
        SchedulePickerComponent,
        SidebarComponent,
        SizeFormatDropDownComponent,
        SizeFormatPickerComponent,
        VersionDropDownComponent,
        VersionPickerComponent,
        EnvironmentPickerComponent,
        ReportingLabelComponent,
        ReportingPickerComponent,
        WelcomePanelComponent,
        // DIRECTIVES
        ClickOutsideDirective,
        DebounceInputDirective,
        InViewDirective,
        NumbersOnlyDirective,
        NavigatorLinkDirective,
        // PIPES
        CapitalizePipe,
        EnumToArrayPipe,
        MapPropertyPipe,
        ObjectSearchPipe,
        ToSentencePipe,
        ToSecondsPipe,
        ToTimeAgoPipe,
        UrlToDomainPipe],
    exports: [
        // MODULES
        UIModule,
        // COMPONENTS
        BFDropDownComponent,
        BFSelectableComponent,
        BFSelectableDirective,
        BFSelectableListComponent,
        BannerComponent,
        BannerFormatDropDownComponent,
        BannerFormatPickerComponent,
        BannerSetDropDownComponent,
        BannerSetPickerComponent,
        BrandDropDownComponent,
        BrandPickerComponent,
        CampaignPickerComponent,
        CampaignDropDownComponent,
        CommentsComponent,
        CommentInputComponent,
        CountryDropDownComponent,
        CountryPickerComponent,
        AdGroupPickerComponent,
        AdGroupDropDownComponent,
        HeaderContentComponent,
        HeaderContentBrandPickerComponent,
        LocalizationDropDownComponent,
        LocalizationPickerComponent,
        ObjectPickerComponent,
        ObjectDropDownComponent,
        RadioGroupComponent,
        ScheduleDropDownComponent,
        SchedulePickerComponent,
        SidebarComponent,
        SizeFormatDropDownComponent,
        SizeFormatPickerComponent,
        VersionDropDownComponent,
        VersionPickerComponent,
        ProfileImageComponent,
        EnvironmentPickerComponent,
        ReportingLabelComponent,
        ReportingPickerComponent,
        WelcomePanelComponent,
        // DIRECTIVES
        DebounceInputDirective,
        InViewDirective,
        NavigatorLinkDirective,
        NumbersOnlyDirective,
        ClickOutsideDirective,
        // PIPES
        CapitalizePipe,
        EnumToArrayPipe,
        MapPropertyPipe,
        UrlToDomainPipe,
        ObjectSearchPipe,
        ToSecondsPipe,
        ToSentencePipe,
        ToTimeAgoPipe
    ],
    providers: [
        // Angular pipes
        DatePipe,
        I18nPluralPipe,
        CommentsService,
        // PIPES
        CapitalizePipe,
        EllipsisPipe,
        EnumToArrayPipe,
        MapPropertyPipe,
        ObjectSearchPipe,
        ToSentencePipe,
        ToSecondsPipe,
        ToTimeAgoPipe,
        UrlToDomainPipe
    ]
})
export class SharedModule {}
