import { Injectable } from '@angular/core';
import { UINotificationType } from '@bannerflow/ui';
import { BehaviorSubject, Observable } from 'rxjs';

export interface NotificationInfo {
    type: UINotificationType;
    shouldShow: boolean;
    message: string;
    loader: boolean;
}

@Injectable({ providedIn: 'root' })
export class StickyNotificationService {
    private readonly _notificationInfo = new BehaviorSubject<NotificationInfo>({
        type: 'success',
        shouldShow: false,
        message: 'yeye',
        loader: true
    });
    notificationInfo$: Observable<NotificationInfo> = this._notificationInfo.asObservable();

    setNotificationInfo(notificationInfo: NotificationInfo): void {
        this._notificationInfo.next(notificationInfo);
    }
}
