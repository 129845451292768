import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ExObjectItem, ExObjectQuery } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
    selector: 'objectPicker',
    template: '',
    standalone: true
})
export class ObjectPickerComponent implements OnInit, OnDestroy {
    @Input() public folder: string;

    private _brandId: string;
    get brandId(): string {
        return this._brandId;
    }
    
    @Input()
    set brandId(value: string) {
        this._brandId = value;
        this.currentFolder = null;
        this.folder = null;
        this.loadItems();
    }

    @Input() public multi = false;
    @Input() public placeholder = 'None';
    @Input() public allowNoneSelection: boolean;

    @Input() public selected: any[] = [];
    @Input() public selectedObjects: any | any[] = [];

    @Output() protected selectedChange: EventEmitter<any> = new EventEmitter<any>(); // Emit pure ID:s

    @ViewChild('datatable') protected datatable: DatatableComponent;

    public type: ObjectType;
    public tableRowsOriginal: any;
    public tableRows: any;
    public loading: boolean;
    public breadcrumbs: any[];
    public currentFolder: string;
    public searchQuery: string;

    // Pagination
    public currentPage = 1;
    public currentCount = 0;
    public hasMore = true;
    public initialLoad = true;

    public selectedIds: string[];

    protected PAGE_SIZE = 25;

    private scrollEventListener: Function;
    public SelectionType: typeof SelectionType = SelectionType;

    constructor(
        protected elementRef: ElementRef,
        protected renderer: Renderer2
    ) {
        this.type = ObjectType.BannerSet;
        this.currentCount = this.PAGE_SIZE;
    }

    protected loadItems(selectedKey = 'id'): void {
        if (this.multi) {
            this.selectedObjects = this.tableRows.filter((row: any) => {
                const item: ExObjectItem<any> = this.getRowValue(row);
                const selected: any = this.selected
                    ? this.selected.find((id: string) => id === item.data[selectedKey])
                    : undefined;

                return !!selected;
            });
        } else {
            if (this.allowNoneSelection) {
                this.tableRows = [{ id: null, name: this.placeholder }, ...this.tableRows];
            }
        }

        this.tableRowsOriginal = this.tableRows;
    }

    public onTableClick(tableEvent: any): void {
        // NgxDatatable sends two events when checkbox gets clicked, this ignores one of them.
        if (tableEvent.type === 'checkbox') {
            return;
        } else if (tableEvent.type === 'click' || tableEvent.type === 'dblclick') {
            // Handle brands because they have no meta api endpoint for brands
            if (this.type === ObjectType.Brand || this.type === ObjectType.Country) {
                if (this.multi) {
                    const selectedId: any = tableEvent.row.id;

                    this.toggleItem(selectedId);

                    this.selectedChange.emit(this.selected);
                } else {
                    this.selectedChange.emit(tableEvent.row.id ? tableEvent.row : null);
                }
            } else {
                const row: any = tableEvent.row;
                const item: any = this.getRowValue(row);

                if (!item.isFolder) {
                    if (this.multi) {
                        const selectedId: string = item.data.id;
                        this.toggleItem(selectedId);

                        this.selectedChange.emit(this.selected);
                    } else {
                        this.selectedChange.emit(item.data);
                    }
                } else {
                    this.initialLoad = true;
                    // Set current folder
                    this.currentFolder = item.data.id;

                    // If item is a folder, load items with specified folder
                    this.loadItems();
                }
            }
        }
    }

    protected toggleItem(selectedId: string): void {
        if (!this.selected) {
            this.selected = [];
        }

        const alreadySelectedIndex: number = this.selected.findIndex((selected: string) => selected === selectedId);

        if (alreadySelectedIndex === -1) {
            this.selected.push(selectedId);
        } else {
            this.selected.splice(alreadySelectedIndex, 1);
        }
    }

    protected getPreviousFolderId(): string {
        if (this.breadcrumbs && this.breadcrumbs.length) {
            return this.breadcrumbs[this.breadcrumbs.length - 1].id;
        } else {
            return null;
        }
    }

    protected getRowValue(row: any): ExObjectItem<any> {
        if (!row.data) {
            const item: ExObjectItem<any> = new ExObjectItem<any>();
            item.data = row;

            return item;
        } else {
            return row;
        }
    }

    public goUp(): void {
        this.initialLoad = true;

        const folderToGo: string = this.getPreviousFolderId();

        this.currentFolder = folderToGo;
        this.folder = null;

        this.loadItems();
    }

    public search(): void {
        if (
            this.type === ObjectType.BannerSet ||
            this.type === ObjectType.Feed ||
            this.type === ObjectType.Schedule ||
            this.type === ObjectType.LandingPage
        ) {
            this.initialLoad = true;

            this.loadItems();
        } else {
            this.tableRows = this.tableRowsOriginal.filter((row: any) => {
                let rowString: string = JSON.stringify(row).toLowerCase();

                // If the user searches for BannerFormat or SizeFormat, we'll add the specific width x height as a search possibility.
                if (this.type === ObjectType.BannerFormat) {
                    rowString += `${row.sizeFormat.size.width}x${row.sizeFormat.size.height}`;
                } else if (this.type === ObjectType.SizeFormat) {
                    rowString += `${row.size.width}x${row.size.height}`;
                }

                return rowString.indexOf(this.searchQuery.toLowerCase()) !== -1;
            });
        }
    }

    public canSelect = (row: any): boolean => {
        return !row.isFolder;
    };

    public clear(): void {
        this.selected = [];
        this.selectedObjects = [];

        this.selectedChange.emit(this.selected);
    }

    protected getSearchQuery(): ExObjectQuery {
        const query: ExObjectQuery = new ExObjectQuery();
        query.folder = this.currentFolder || null;
        query.query = this.searchQuery;
        query.count = this.currentCount;
        query.includeSubFolders = query.query ? true : false;

        if (this.brandId) {
            query.brand = this.brandId;
        }

        return query;
    }

    public ngOnInit(): void {
        this.loadItems();
    }

    public ngOnDestroy(): void {
        if (this.scrollEventListener) {
            this.scrollEventListener();
        }
    }
}
