export enum PreviewSettingsShow {
    AllBanners = 0,
    OnlyApproved = 1,
    OnlyNonApproved = 2
}

export enum PreviewSettingsTarget {
    NewWindow = 0,
    SameWindow = 1
}

export class BannerTagSettings {
    public showTags: boolean;
    public target: PreviewSettingsTarget = PreviewSettingsTarget.NewWindow;
    public targetUrl: string;
    public responsive: boolean;
}
export class PreviewSettings {
    public images = false;
    public show: PreviewSettingsShow = PreviewSettingsShow.AllBanners;
    public optimizedLoading = true;
    public versions: string[] = [];
    public sizeFormats: string[] = [];
    public bannerTag: BannerTagSettings = new BannerTagSettings();
    public bannerId: string;
    public adType: string;
    public commentsSecret: string;

    static fromQueryString(queryString: any): PreviewSettings {
        const previewSettings: PreviewSettings = new PreviewSettings();

        // Approval settings
        if (queryString.sf === '1') {
            previewSettings.show = PreviewSettingsShow.OnlyApproved;
        } else if (queryString.sf === '2') {
            previewSettings.show = PreviewSettingsShow.OnlyNonApproved;
        }

        // Filter settings - Versions
        if (queryString.v) {
            previewSettings.versions = queryString.v.split(',');
        }

        // Filter settings - SizeFormats
        if (queryString.ff) {
            previewSettings.sizeFormats = queryString.ff.split(',');
        }

        // banner tags settings - Show banner tags
        if (queryString.et) {
            previewSettings.bannerTag.showTags = true;
        }

        // banner tags settings - Target
        if (queryString.tw) {
            previewSettings.bannerTag.target =
                queryString.tw === '_top' ? PreviewSettingsTarget.NewWindow : PreviewSettingsTarget.SameWindow;
        }

        // Bannertag settings - Target url
        if (queryString.tu) {
            previewSettings.bannerTag.targetUrl = queryString.tu;
        }

        // Bannertag settings - Responsive
        if (queryString.r) {
            previewSettings.bannerTag.responsive = true;
        }

        // Single banner
        if (queryString.a) {
            previewSettings.bannerId = queryString.a;
        }

        // Hide or show comments
        if (queryString.c) {
            previewSettings.commentsSecret = queryString.c;
        }

        return previewSettings;
    }
}
