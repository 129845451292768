import { Serializable } from '@shared/interfaces/serializable.interface';

export class Size implements Serializable<Size> {
    public name: string;
    public width: number;
    public height: number;

    public normalizedWidth(width: number, height: number): number {
        return this.width * Math.min(width / this.width, height / this.height);
    }

    public normalizedHeight(width: number, height: number): number {
        return this.height * Math.min(width / this.width, height / this.height);
    }

    public fittedSize(width: number, height: number): Size {
        const scale: number = Math.min(width / this.width, height / this.height);

        const size: Size = new Size();

        if (scale < 1) {
            size.width = this.width * scale;
            size.height = this.height * scale;
        } else {
            size.width = this.width;
            size.height = this.height;
        }

        return size;
    }

    public scaledSize(width: number, height: number, scale: number): Size {
        const size: Size = new Size();

        size.width = width * scale;
        size.height = height * scale;

        return size;
    }

    public deserialize(json: any): Size {
        if (!json) {
            return this;
        }

        this.name = json.name;
        this.width = json.width;
        this.height = json.height;

        return this;
    }
}
