import { Injectable, inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HotkeyService {
    eventManager = inject(EventManager);
    // constructor(private eventManager: EventManager) {}

    /**
     * Adds a global event listener to document via angulars EventManager
     *
     * EventManager allows key combinations like the following;
     *
     * `control.z`
     *
     * `control.shift.z`
     *
     * @param keys
     */
    public addShortcut(keys: string): Observable<KeyboardEvent> {
        const event = `keydown.${keys}`;

        return new Observable<KeyboardEvent>(observer => {
            // handles keyboard event and passes outcome o observer
            const handler: (e: any) => void = e => {
                e.preventDefault();
                observer.next(e);
            };

            const dispose: Function = this.eventManager.addEventListener(document.body, event, handler);

            return () => {
                dispose();
            };
        });
    }
}
